import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Taurus.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Taurus Nature
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/taurus"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Taurus</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Taurus Nature</h4>
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Taurus Nature </h1>
                <h2 className="text-sm md:text-base ml-4">Apr 20 - May 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-love");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-nature");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-man");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-traits");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-facts");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                <p className="playfair text-black text-xl md:text-3xl">Taurus Nature</p><br/>
            Taurus, the zodiac's second sign, is a sign that harvests delicious fruit from diligent work. Being a bull, you have the benefit of being a heavenly nature creature. The power and strength of bull charge you, but at the same time, you are soft, patient, and refined. You are easy-going until an individual initiates, tempts, or prompts you. You appreciate relaxing in a quiet and calm atmosphere. You belong to the Earth sign. 
<br/><br/>
This is why access to nature and its beauty is essential for you. You always feel the need to be surrounded by the love and beauty of nature. You love to go to the country and similar sites covered by the soft sound of nature, soothing fragrances, and juicy tastes. You extremely want magnificence and love to be turned to the material world, self-indulgence, and physical satisfaction. You are sensual and keep a strong sense of suggestion.
<br/><br/>


Taurus men and women are known for their perseverance, simplicity, conservativeness, and serenity. You will search for calmness in your choices and the people immediate to you. Many people around you find you boring and lacking in excitement. This may be because you bypass taking the risks that may affect your stability. You are an excellent long-term friend. You are shielding your close ones. You can see things practically and in a realistic manner. It is easy for you to make money. You are consistent in work, stay on the duplicate project for years, and don't vacate it unfinished. You are stubborn about your obligation and will push yourself to the end to finish the tasks. The blocks in the path of success don't matter to you. However, you know to slow down and appreciate amazing things in life.
<br/><br/>

<p className="font-bold inline-block text-orange-500">Taurus Ruling Planet:</p> Venus
<br/><br/>
Taurus is ruled by Venus, the planet associated with beauty, attraction, love, satisfaction, creativity, and thankfulness. The ruling planet gives you a natural charm and develops a strong urge to maintain harmonious connections. You tend to get swept off your feet by fantasy and beauty. You crave tranquillity and appreciate the finer things in life. 
<br/><br/>
When you see a beautiful painting or any other work of art and enjoy it, you may be sure it is the outcome of Venus. You love the simplicity and hate complications in your life. You are alluring and have a keen eye for all the good things. You believe in long-term ties and often have a happy and long-lasting marriage. You long for peace and harmony and will rarely venture out of your relationship. The energy of Venus will constantly invent artistic and cooking talents in you.<br/><br/>
<p className="font-bold inline-block text-orange-500">Ruling House:</p> Second (Possession)<br/><br/>
The second house represents the possession of material and non-material things in the world. Property, wealth, and human relationships are equally essential for you. You value the senses and material things in the world. The house rules money, income, and self-esteem, and hence these things are essential for you. The leverage of the second house makes you think about what is necessary for you. You are more concerned about security, luxury, losses, profits, and financial requirements.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Habits</p><br/>
You have the habit of saying no before someone finishes explaining a possibility to you. Sometimes, you end up saying no to excellent opportunities for life. You are very lazy to keep all the things organised. You don't care about your financial budget and go shopping whenever you like. When you are stressed, you grind your teeth and clench your jaws. You are not convinced about trying something when you are uncomfortable and have a favourite way of doing it. You avoid life changes, even if it is at the cost of experiencing new things.<br/><br/>
<p className="font-bold inline-block text-orange-500">Element:</p> Earth<br/><br/>
Being a fixed earth sign, you are highly leveled and practical in your approach. You never escape from the reality of the world and face all the obstacles coming along the path very strongly. You support your close ones who are highly passionate. You desire finer things like indulgent food and luxurious possessions that stimulate your physical senses. The element will also strengthen your command for saving material security and stability. You are pretty self-reliant.<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Strengths</p><br/>
You are a loyal and reliable person. You do things after taking ample time and act only after completing the decisions knowingly. You make the right selection. You are pretty patient with the people around you. You give time and space to them to do what they want to do. Your forte is to have a pragmatic outlook on life. You are determined and diligent in finishing tasks well.<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Weaknesses</p><br/>
Your most prominent weakness is the possessiveness of the material world, especially when involved in love affairs. You don't like prompt changes in your life. You don't adjust your mind after you make a decision. You would be very tough on yourself when you cannot conclude tasks as per your expectations.<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Eminent Personality</p><br/>
Queen Elizabeth II, Miranda Kerr, George Clooney, Amber Heard, Gigi Hadid, John Cena, Joe Kerry, Kelly Clarkson, JessicaAlba, David Beckham, Megan Fox, Jack Nicholson, Janet Jackson, Jerry Seinfeld, Kenan Thompson, Robert Pattinson, Channing Tatum, Tony Blair, Gal Gadot, Travis Scott, Dwayne Johnson, Dev Patel Madhuri Dixit, Anushka Sharma, Varun Dhawan, Manoj Bajpayee, Satyajit Ray, Sunny Leone, Pooja Bedi, Zarine Khan, Mamta Kulkarni, Masumi Chatterjee.
<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
